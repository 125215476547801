@import url('https://fonts.googleapis.com/css2?family=Asap:wght@100;200;300;400;500;600;700&display=swap');

:root {
  --primary_01: #18194d;
  --primary_light_a03: rgb(203, 206, 244, 0.3);
  --primary_light_a02: rgb(203, 206, 244, 0.2);
  --primary_light_a005: rgb(203, 206, 244, 0.1);
  --text_default: #e2daf7d5;
  --text_default_light: rgba(226, 218, 247, 0.452);
  --text_strong: #fff;
  --secondary: #FFD059;
  --img_bg: rgba(255, 255, 255, 0.149);
}

html {
  font-size: 62.5%;
}

@media screen and (max-width: 768px) {
  html {
    font-size: 56.25%;
  }
}

html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  background: var(--primary_01);
  font-family: 'Asap', sans-serif;
}

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: var(--text_default);
}

ul {
  list-style: none;
}